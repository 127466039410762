.cards {
  display: flex;
  flex-wrap: wrap;
}

.card {
  flex: 20%;
  margin: 1rem;
  padding: 1rem;
}
