body {
  font-family: -apple-system, 'BlinkMacSystemFont', 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
a {
  text-decoration: none;
}
a,
a:visited {
  color: blue;
}
.warningMsg {
  color: red;
  text-align: center;
}
.highlight {
  color: blue;
}
.messaging {
  clear: both;
  font-size: 20px;
  color: blue;
  text-align: center;
  -webkit-animation: seconds 1s forwards;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-delay: 3s;
  animation: seconds 1s forwards;
  animation-iteration-count: 1;
  animation-delay: 3s;
  position: relative;
}
@-webkit-keyframes seconds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: -9999px;
  }
}
@keyframes seconds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: -9999px;
  }
}

.App-main {
  width: 90%;
  padding-right: 2%;
  padding-left: 2%;
  margin: 0 auto;
  margin-top: 90px;
  min-height: 600px;
}
.mainMenuNavLink {
  display: inline-block;
  text-decoration: none;
  width: 12.5%;
}

.SiteMenu {
  display: flex;
  color: #fff;
}

.SiteMenu ul {
  display: flex;
  padding: 0;
  list-style: none;
  justify-content: flex-start;
  margin: 0;
  align-items: center;
  flex: 1;
}

.SiteMenu li {
  position: relative;
  display: block;
}

.SiteMenu li:last-child {
  margin-right: 0;
}

.SiteMenu li li,
.SiteMenu li li:first-child,
.SiteMenu li li:last-child {
  min-width: 10vw;
  margin: 0;
}

.placeholder {
  display: block;
  padding: 1rem;
  color: #fff;
}

.SiteMenu a {
  display: block;
  color: #fff;
}

.test {
  margin-right: 5px;
  margin-top: -10px;
}

.SiteMenu .submenu {
  position: absolute;
  top: calc(100%);
  left: 0;
  display: none;
  background: #039be5;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14);
}

.open .submenu {
  display: block;
}

.logout-avatar:hover {
  cursor: pointer;
}

.submenu .placeholder {
  padding: 0;
}

.submenu .placeholder a {
  padding: 0.75rem;
}

.content {
  border: 1px solid red;
}

.header {
  border: 1px solid blue;
}

.resultsList {
  list-style: none;
}

.loginForm {
  width: 14%;
  float: none;
  margin: 0 auto;
  margin-top: 60px;
}
.center-pills {
  display: flex;
  justify-content: center;
}
.leftContainer {
  width: 52%;
  float: left;
  margin-top: 25px;
}

.sideContextContainer {
  width: 44%;
  float: left;
  text-align: center;
  margin-top: 25px;
  margin-left: 3%;
}
.sideMenuContent {
  margin-top: 25px;
  text-align: center;
}
.topContextContainer {
  background-color: #efefef;
  border-radius: 12px;
  padding: 16px;
}
.centerBlock {
  text-align: center;
}

footer {
  text-align: center;
  margin-top: 50px;
  clear: both;
}

.widthNinety {
  width: 90%;
}
.widthEighty {
  width: 80%;
}
.days5,
.days6,
.days7,
.days8,
.days9,
.days10,
.days11,
.days12,
.days13,
.days14,
.days15,
.day16,
.days17,
.days18 {
  background-color: rgba(0, 255, 0, 0.8);
}
.days4,
.days3 {
  background-color: rgba(255, 255, 0, 0.8);
}
.days2,
.days1,
.days0 {
  background-color: rgba(255, 128, 0, 0.8);
}
.days-1,
.days-2,
.days-3,
.days-4,
.days-5,
.days-6,
.days-7,
.days-8,
.days-9,
.days-10,
.days-11,
.days-12,
.days-13,
.days-14,
.days-15,
.days-16,
.days-17,
.days-18,
.days-19,
.days-20,
.days-21,
.days-22,
.days-23,
.days-24,
.days-25,
.days-26 {
  background-color: rgba(255, 0, 0, 0.8);
}

.tracking_day {
  text-align: center;
  background-color: #eeeeee;
  padding: 1%;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  margin-bottom: 0;
}

/* ORDER STATUS BOARD */

.hoverBox {
  position: fixed;
  padding: 10px;
  background: #e5ecf9;
  color: #000000;
  border: 1px solid #a7bbe1;
  top: 30%;
  left: 27%;
  z-index: 99;
  text-transform: capitalize;
}
.hoverBox ul {
  list-style: none;
}
.hoverBox .hoverBoxLeft {
  float: left;
}
.hoverBox .hoverBoxRight {
  float: right;
}
.hoverBox .label {
  font-weight: bold;
}
.triggerDiv {
  display: inline-block;
  text-transform: capitalize;
}
.triggerDiv:hover {
  cursor: pointer;
}
.triggerDiv .redText {
  color: #ff0000;
}
.triggerDiv .greenText {
  color: #006400;
}
.triggerDiv .standard {
  color: #888888;
}
.blink {
  -webkit-animation: blink 3s linear infinite;
  -moz-animation: blink 3s linear infinite;
  -ms-animation: blink 3s linear infinite;
  -o-animation: blink 3s linear infinite;
  animation: blink 3s linear infinite;
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
#statusBar {
  background-color: #039be5;
  text-transform: uppercase;
  height: 30px;
  color: #ffffff;
  line-height: 30px;
  font-size: 1.2em;
  font-weight: bold;
  padding: 6px 10px;
  letter-spacing: 0.08em;
  margin-top: 18px;
}
.clearNotification {
  float: right;
  line-height: 15px;
  font-size: 0.65em;
  padding-top: 6px;
}
#invoiceList {
  padding: 15px 10px;
  line-height: 28px;
  letter-spacing: 0.08em;
}
.clear {
  clear: both;
}
.noti_bubble {
  position: absolute;
  top: -3px;
  right: -6px;
  padding: 1px 2px 1px 2px;
  background-color: red;
  color: white;
  font-weight: bold;
  font-size: 0.9em;
  border-radius: 5px;
  box-shadow: 1px 1px 1px gray;
  line-height: 1.3em;
  min-width: 10px;
}
.standout {
  font-size: 1.1em;
  color: #fff;
  background-color: #204472;
  border: 1px solid #575757;
  border-radius: 10px;
  padding-bottom: 2px;
  letter-spacing: 0.08em;
}
.danger {
  color: #fff;
  background-color: #ff0000;
  border: 1px solid #575757;
  border-radius: 10px;
  padding-bottom: 2px;
  letter-spacing: 0.08em;
}
.formInputSuccess {
  border-color: transparent; /* remove the border's colour */
  box-shadow: 0 0 0 3px rgb(4, 196, 4); /* emulate the border */
}
.formInputFail {
  border-color: transparent; /* remove the border's colour */
  box-shadow: 0 0 0 3px rgb(240, 15, 15); /* emulate the border */
}
