.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.form-element {
  padding: 1rem;
}

.select {
  flex: 1;
  min-width: 150px;
  width: 100%;
}

.select-form {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 4;
}
