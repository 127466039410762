.title {
  font-size: 1.3em;
  font-weight: bold;
  flex: 1;
  margin-left: 34px;
}
.transfer-header {
  display: inline-block;
  width: 15%;
  margin-top: 14px;
}
.transfer-form-field {
  flex: 1;
}
.field-label {
  font-size: 0.8em;
  color: #6b6b6b;
  display: block;
  margin-bottom: 3px;
}
.field-label-required {
  font-size: 0.9em;
  color: red;
  display: block;
  margin-bottom: 3px;
}
.field-msg {
  font-size: 0.8em;
  display: block;
  margin-bottom: 6px;
}
.center {
  text-align: center;
}
.addFormFields {
  margin-top: 12px;
}
.scanRequiredMsg {
  background-color: #efefef;
  border-radius: 3px;
  width: 90%;
  height: 70%;
  padding: 3px;
}
.commit-transfer-btn {
  background-color: #f9f29a;
}
/* .submit-transfer-btn {
    background-color: #e5030361;
} */
.submit-transfer-btn {
  background-color: #f9a9a5;
}
/* .successMsg {
    color: green;
    font-size: 2em;
    text-align: center;
}
.failedMsg {
    color: red;
    font-size: 1.6em;
} */
textarea {
  resize: none;
}
.deleteBtn {
  background-color: red;
  margin-top: 12px;
  border-radius: 2px;
  color: black;
}
.msgStyle {
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  height: 74px;
  font-size: 1.6em;
  animation: msg-response 8s 1;
  -webkit-animation: msg-response 8s 1;
  animation-fill-mode: forwards;
  z-index: 100;

  animation-delay: 3s;
  -webkit-animation-delay: 3s; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
}
@keyframes msg-response {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes msg-response {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.successMsg {
  text-align: center;
  background: rgba(0, 255, 0, 0.8);
  font-size: 2.2em;
  line-height: 74px;
}
.failedMsg {
  background: rgba(255, 0, 0, 0.8);
}
