img::-moz-selection {
  background-color: transparent;
  color: #000;
}

img::selection {
  background-color: transparent;
  color: #000;
}

.disable-text-selection {
  user-select: none;
  -ms-user-selec: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

input[type='file']::file-selector-button {
  font-family: 'Open Sans';
}
